<ng-container *ngIf="!loading(); else spinner">
  <div>
    <div class="event-details-header-container">
      <h3>EVENT DETAILS</h3>
      <p *ngIf="hasPaidMembers()">
        To make changes to your event, please contact Customer Service at
        866-783-5767
      </p>
    </div>
    <div class="form-container">
      <div
        style="
          text-align: left;
          width: 100%;
          margin-bottom: 1em;
          font-size: 14px;
        "
      >
        * Required
      </div>
      <form class="details-form" [formGroup]="form">
        <mat-form-field appearance="outline" required>
          <mat-label>Event Name</mat-label>
          <mat-error
            *ngIf="getFirstSchemaFieldError(form.controls.eventName) as error"
            >{{ error }}</mat-error
          >
          <input
            matInput
            formControlName="eventName"
            required
            [maxLength]="EVENT_NAME_MAX_LENGTH"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" required>
          <mat-label>Event Type</mat-label>
          <mat-error
            *ngIf="getFirstSchemaFieldError(form.controls.eventType) as error"
            >{{ error }}</mat-error
          >
          <mat-select formControlName="eventType" required>
            <mat-option
              *ngFor="let eventType of eventTypeOptions"
              [value]="eventType"
              >{{ eventType }}</mat-option
            >
            <!-- <mat-option value="Wedding">Wedding</mat-option>
          <mat-option value="Prom">Prom</mat-option>
          <mat-option value="Quinceañera">Quinceañera</mat-option>
          <mat-option value="Special Event">Special Event</mat-option> -->
          </mat-select>
        </mat-form-field>
        <app-date-form
          appearance="outline"
          textAlign="left"
          label="Event Date"
          [hideHint]="true"
          [initialValue]="event()?.eventDate || 0"
          (valueChange)="setDate($event)"
          [disabled]="dateDisabled()"
          [updateOn]="'change'"
          (onBlur)="onDateBlur()"
          [minDate]="
            form.controls.onlineOrInStore.value === OnlineOrInStore.online
              ? minDateOnline()
              : minDateInStore()
          "
          [maxDate]="maxDate()"
          class="event-date"
        >
        </app-date-form>

        @if (eventCreationEnabled()) {
          <mat-form-field appearance="outline">
            <mat-label>Individual or Group</mat-label>
            <mat-select formControlName="individualOrGroup" required>
              <mat-option [value]="IndividualOrGroup.individual"
                >Individual Order</mat-option
              >
              <mat-option [value]="IndividualOrGroup.group"
                >Group Order</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>How do you want to order?</mat-label>
            <mat-select formControlName="onlineOrInStore" required>
              <mat-option
                [value]="OnlineOrInStore.online"
                [disabled]="isOnlineDisabled()"
                >Order Online
                <span *ngIf="isOnlineDisabled()"
                  >(event date too soon)</span
                ></mat-option
              >
              <mat-option [value]="OnlineOrInStore.inStore"
                >Order In-Store</mat-option
              >
            </mat-select>
          </mat-form-field>
        }

        <mat-error *ngIf="form.hasError('dateIsTooSoon')">
          *Your event date is too soon. <br />
          Online ordering is unavailable for events <br />
          less than three weeks from time of ordering.
        </mat-error>
        <div *ngIf="isSaving()" class="saving-container">
          <!-- <mat-spinner color="primary" diameter="20" strokeWidth="2"></mat-spinner> -->
          <span class="saving">Saving...</span>
          <mat-progress-bar
            color="primary"
            mode="indeterminate"
          ></mat-progress-bar>
        </div>
        <div *ngIf="saved() === true" style="color: green">
          <mat-icon style="vertical-align: text-bottom">done</mat-icon>
          <span>Saved</span>
        </div>
        <div
          *ngIf="saveError() && !form.hasError('dateIsTooSoon')"
          style="color: red; display: flex; gap: 0.2em; align-items: center"
        >
          <mat-icon style="vertical-align: text-bottom">sync_problem</mat-icon>
          <!-- <span>CHANGES NOT SAVED.</span> -->
          <br />
          <span>Please fill out the required fields</span>
        </div>
      </form>
    </div>
  </div>

  <div
    id="event-permissions-container"
    class="event-permissions-container"
    *ngIf="(event()?.members?.length ?? 0) > 0"
  >
    <div class="event-permissions-header-container">
      <h3>{{ eventHasCoOwner() ? "CO-OWNER" : "ADD CO-OWNER" }}</h3>
      @if (eventHasCoOwner()) {
        <p>
          To make changes to your co-owner, please contact Customer Service at
          866-783-5767
        </p>
      } @else {
        <p>Select a member or add a new co-owner to help manage your event</p>
      }
    </div>
    <div class="event-permissions-options-container">
      <div style="display: flex; flex-direction: column">
        <div *ngFor="let admin of coOwners()">
          <div *ngIf="admin.firstName !== undefined && !sendingInvite()">
            <div class="co-owner">
              <b class="admin-name">
                {{ admin.firstName | uppercase }}
                {{ admin.lastName | uppercase }}
              </b>
              <span>{{ phoneFormat(admin.phone) }}</span>
              <span>{{ admin.email }}</span>
              <span class="invite-copy">{{
                !!admin?.inviteAccepted ? "Invite Accepted" : "Invite Sent"
              }}</span>
              <!-- <div *ngIf="showInvite" class="delete-co-owner-container-desktop">
              <button
                mat-icon-button
                (click)="dismissInvite()"
                [ngClass]="!existingMemberSelectOpen() ? '' : 'disabled'"
              >
                <mat-icon>close</mat-icon>
              </button>
              <div class="invite-copy">{{!!admin.userId ? 'Invite Accepted' : 'Invite Sent' }}</div>
            </div>
            --></div>
            <!-- <span class="changes-text">To make changes to your co-owner, please contact Customer Service at 866-783-5767</span> -->
          </div>
        </div>

        <div
          class="disclaimer"
          *ngIf="!hasCoOwnerCapabilities() && coOwners().length < 1"
        >
          <span>
            Due to the timeframe, you are unable to add a co-owner. If you have
            questions, please contact Customer Service at 866-783-5767.
          </span>
        </div>

        <div class="button-container" *ngIf="displayAddCurrentMembers()">
          <div
            class="add-co-owner-button"
            [ngClass]="addCurrentMemberState()"
            mat-flat-button
            data-cy="invite-co-owner"
            color="primary"
            (click)="toggleAddCurrentMemberCoOwner()"
          >
            <div class="invite-co-owner">
              <span> ADD CURRENT MEMBER </span>
              <mat-icon>add</mat-icon>
            </div>
          </div>
          <div
            *ngIf="
              (existingMemberSelectOpen() || sendingInvite()) &&
              !addNewAdminOpen()
            "
            class="existing-member-list-container"
          >
            <div *ngFor="let member of nonAdminMembers(); index as idx">
              <div
                class="member-list-entry"
                [ngClass]="
                  !!getCoOwnerError(member)
                    ? 'disabled'
                    : idx === selectedMemberIdx()
                      ? 'member-selected'
                      : ''
                "
                (click)="selectMemberChange(member.id!, idx)"
              >
                <span
                  >{{ member.firstName | uppercase }}
                  {{ member.lastName | uppercase }}</span
                >
                @if (getCoOwnerError(member); as coOwnerError) {
                  <div
                    style="display: flex; align-items: center; flex: 0 0 auto"
                  >
                    <span class="missing-info"> {{ coOwnerError }}</span>
                    <button
                      mat-icon-button
                      class="edit-co-owner-button"
                      (click)="editCoOwner()"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                  </div>
                }
              </div>
            </div>

            <div class="invite-container">
              <button
                [disabled]="selectedMemberIdx === undefined || sendingInvite()"
                color="primary"
                mat-flat-button
                (click)="inviteCoOwner()"
              >
                <ng-container *ngIf="!sendingInvite(); else spinner">
                  INVITE CO-OWNER
                </ng-container>

                <ng-template #spinner>
                  <mat-progress-spinner
                    color="accent"
                    value="100"
                    mode="indeterminate"
                    style="margin-left: auto; margin-right: auto"
                    diameter="20"
                    strokeWidth="2"
                  >
                  </mat-progress-spinner>
                </ng-template>
              </button>
            </div>
          </div>
        </div>

        <div
          class="button-container"
          *ngIf="coOwners().length < 1 || addNewAdminOpen()"
        >
          <div
            class="add-co-owner-button"
            [ngClass]="addNewAdminState()"
            mat-flat-button
            data-cy="invite-co-owner"
            color="primary"
            (click)="toggleAddNonMemberCoOwner()"
          >
            <div class="invite-co-owner">
              <span> ADD NON-MEMBER </span>
              <mat-icon>add</mat-icon>
            </div>
          </div>
          <div>
            <div *ngIf="addNewAdminOpen()" class="permissions-row">
              <form
                *ngIf="addNewAdminOpen()"
                [formGroup]="permissionsForm"
                (ngSubmit)="inviteCoOwner()"
              >
                <div class="grid">
                  <p
                    [ngClass]="
                      requiredWarn() ? 'required-text warn' : 'required-text'
                    "
                  >
                    * Required
                  </p>
                  <mat-form-field appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="firstName" required />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="lastName" required />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" required />
                    <mat-error>Please enter a valid email address.</mat-error>
                  </mat-form-field>
                  <app-phone-form
                    appearance="outline"
                    (formReady)="addChildPermissionsForm('phone', $event)"
                    (valueChange)="setPhone($event)"
                  >
                  </app-phone-form>
                  <div class="panel-footer">
                    <div class="panel-buttons">
                      <button
                        data-cy="save"
                        mat-flat-button
                        type="submit"
                        color="primary"
                        [disabled]="permissionsForm.invalid"
                      >
                        <ng-container *ngIf="!sendingInvite(); else spinner">
                          INVITE CO-OWNER
                        </ng-container>

                        <ng-template #spinner>
                          <mat-progress-spinner
                            color="accent"
                            value="100"
                            mode="indeterminate"
                            style="margin-left: auto; margin-right: auto"
                            diameter="20"
                            strokeWidth="2"
                          >
                          </mat-progress-spinner>
                        </ng-template>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text" *ngIf="errorSending()">
    There was an error sending the invite.
  </div>
  <!-- <div *ngFor="let admin of coOwners()">
  <div *ngIf="admin.firstName !== undefined && showInvite" class="delete-co-owner-container-mobile">
    <div class="triangle"></div>
    <div *ngIf="showInvite" class="invite-copy">
      <button
        mat-icon-button
        (click)="dismissInvite()"
        [ngClass]="!existingMemberSelectOpen() ? '' : 'disabled'"
      >
        <mat-icon>close</mat-icon>
      </button>
      <div class="text" *ngIf="!errorSending">The invite has been sent</div>
    </div>
  </div>
</div> -->
</ng-container>

<ng-template #spinner>
  <mat-spinner
    style="margin-left: auto; margin-right: auto; margin-top: calc(33% - 50px)"
  ></mat-spinner>
</ng-template>
